<template>
  <div class="withdraw-mb">
    <PageHeader title="Rút tiền" :from-withdraw="true" :show-home-from-page="true" />
    <div class="withdraw-mb__content">
      <div v-for="(item, index) in MENU_WITHDRAW" :key="index" class="menu-item" @click="handleTabMenuWithdraw(item)">
        <BaseImg v-if="item.isHot" class="icon-hot" src="/assets/images/icon/hot.svg" alt="tag" />
        <span class="icon" :class="item.iconMB" />
        <div class="title">{{ item.name }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import PageHeader from '~/components/mobile/common/page-header'
import NoteCollapse from '~/components/mobile/common/note-collapse'
import { useInitData } from '~/composables/user/useInitData'
import { MENU_WITHDRAW } from '~/constants/menu'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { LABEL, NOTES_PAGE_LIST } from '~/constants/account/withdraw/card'
import { IMenuWithDraw } from '~/types/menu.type'

const { $device } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const { toggleMenuWithdraw } = useToggleMenu()

const handleTabMenuWithdraw = (item: IMenuWithDraw) => {
  if ($device.isMobileOrTablet) {
    toggleMenuWithdraw()
    router.push({ path: item?.link })
  }
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/page-withdraw/index.scss" />
