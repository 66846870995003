<template>
  <div id="header-mobile" class="header">
    <BaseImg
      id="logo-mobile"
      class="logo"
      :src="`${PATH_BRAND}logo.webp`"
      alt="logo"
      @click="navigateToHome"
    />
    <div class="header-button">
      <client-only>
        <MobileHeaderUserNotLogin v-if="!currentUser" />
        <MobileHeaderUserLogged v-else />
      </client-only>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PATH_BRAND } from '~/constants/path'
import MobileHeaderUserNotLogin from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
import { PAGE_URLS } from '~/config/page-url'
const { currentUser } = useHeader()
const route = useRoute()
const router = useRouter()

const navigateToHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  if (route.fullPath === '/') {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/header/index.scss" />
