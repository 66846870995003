<template>
  <div class="menu-button">
    <div
      v-for="(item, index) in menu"
      :key="index"
      class="menu-item"
      :class="{
        active: checkActive(item)
      }"
      @click="clickMenuItem(item)"
    >
      <div class="action-wrap__icon">
        <BaseImg class="icon" :src="item.icon" :alt="item.name" />
      </div>
      <div class="text">{{ item.name }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BOTTOM_MENU } from '~/constants/menu'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuBottom } from '~/types/menu.type'
import { PAGE_URLS } from '~/config/page-url'

const route = useRoute()
const { toggleMenuAccount } = useToggleMenu()

const menu = computed(() => {
  return BOTTOM_MENU.filter((item) => item.name !== (route.path === PAGE_URLS.HOME ? 'Trang chủ' : 'Khuyến mãi'))
})

const clickMenuItem = (item: IMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
    return
  }
  navigateTo(item.url)
}
const checkActive = (item: IMenuBottom) => {
  if (item.url === BOTTOM_MENU[1].url) {
    return false
  } else if (route.params?.type === 'quay-so' && route.name !== 'casino') {
    return route.fullPath === item.url || route.fullPath.includes(item.url)
  } else {
    return route.fullPath.includes(item.activeUrl) || route.fullPath.includes(item.activeUrl)
  }
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
